import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { verificationApi } from '../../utils/func';
import { RootState } from '../../app/store';
import EventsService from '../../services/API/Events';
/* eslint-disable no-param-reassign, complexity */

export interface ErrorType {
  code: number | undefined;
  message: string;
}

export interface TicketTypesItemType {
  id: number;
  name: string;
  description: string;
  price: number;
  stock: number;
  ceilingPrice: number;
  purchaseLimit: number;
  image: string;
  imageType: string;
  thumbnailUrl: string;
  thumbnailType: string;
  externalLink: string;
  blockchainUrl: string;
  royaltiesFee?: number;
}
export interface EventDashboardDataType {
  buyerCount: number;
  endTime: string;
  id: number;
  image: string;
  name: string;
  priceTotal: number;
  publishTime: string;
  startTime: string;
  status: number;
  ticketSold: number;
  attendees: {
    ticketScanned: number;
    uniqueUser: number;
  };
  secondaryMarket: {
    count: number;
    royaltiesFee: number;
  };
  ticketTypes: {
    image: string;
    importTotal: number;
    name: string;
    sellEndTime: string;
    sellStartTime: string;
    soldTotal: number;
    stock: number;
    partnerData: {
      count: number;
      name: string;
    }[];
  }[];
}

export interface TicketSalesAnalysisProps {
  date: string;
  partners: {
    count: number;
    name: string;
  }[];
}

/**
 * Partner Event Dashboard
 */
export const getPartnerEventDashboardAction = createAsyncThunk<
  EventDashboardDataType,
  string,
  {
    rejectValue: ErrorType;
  }
>(
  'getPartnerEventDashboard/getPartnerEventDashboardAction',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventsService.getPartnerEventDashboard(payload);
      if (verificationApi(response)) {
        return response;
      }
      return rejectWithValue({
        code: response.code,
        message: response.message,
      } as ErrorType);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({
        message: err.response,
      } as ErrorType);
    }
  },
);

/**
 * Partner Event Dashboard Ticket Sales Analysis
 */
export const getPartnerTicketSalesAnalysisAction = createAsyncThunk<
  TicketSalesAnalysisProps[],
  { id: string; startDate: string; endDate: string },
  {
    rejectValue: ErrorType;
  }
>(
  'getPartnerTicketSalesAnalysis/getPartnerTicketSalesAnalysisAction',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventsService.getPartnerTicketSalesAnalysis(
        payload,
      );
      if (verificationApi(response)) {
        return response;
      }
      return rejectWithValue({
        code: response.code,
        message: response.message,
      } as ErrorType);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({
        message: err.response,
      } as ErrorType);
    }
  },
);

interface EventDashboardState {
  loading: boolean;
  ticketSalesAnalysisLoading: boolean;
  ticketSalesAnalysis: TicketSalesAnalysisProps[];
  data: EventDashboardDataType;
  error:
    | {
        code: number | undefined;
        message: string | undefined;
      }
    | undefined
    | null;
}

const initialState: EventDashboardState = {
  loading: true,
  ticketSalesAnalysisLoading: true,
  ticketSalesAnalysis: [],
  data: {
    buyerCount: 0,
    endTime: '',
    id: 0,
    image: '',
    name: '',
    priceTotal: 0,
    publishTime: '',
    startTime: '',
    status: 0,
    ticketSold: 0,
    attendees: {
      ticketScanned: 0,
      uniqueUser: 0,
    },
    secondaryMarket: {
      count: 0,
      royaltiesFee: 0,
    },
    ticketTypes: [],
  },
  error: null,
};

export const eventDashboardSlice = createSlice({
  name: 'eventDashboard',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPartnerEventDashboardAction.pending, (state) => {
        state.data = {} as EventDashboardDataType;
        state.loading = true;
      })
      .addCase(
        getPartnerEventDashboardAction.fulfilled,
        (state, action: any) => {
          state.loading = false;
          state.data = action.payload.data;
        },
      )
      .addCase(getPartnerEventDashboardAction.rejected, (state, action) => {
        state.loading = false;
        state.ticketSalesAnalysisLoading = false;
        if (action.payload) {
          state.error = action.payload as ErrorType;
        } else {
          state.error = action.error as ErrorType;
        }
      })
      .addCase(getPartnerTicketSalesAnalysisAction.pending, (state) => {
        state.ticketSalesAnalysis = [];
        state.ticketSalesAnalysisLoading = true;
      })
      .addCase(
        getPartnerTicketSalesAnalysisAction.fulfilled,
        (state, action: any) => {
          state.ticketSalesAnalysisLoading = false;
          state.ticketSalesAnalysis = action.payload.data;
        },
      )
      .addCase(
        getPartnerTicketSalesAnalysisAction.rejected,
        (state, action) => {
          state.ticketSalesAnalysisLoading = false;
          if (action.payload) {
            state.error = action.payload as ErrorType;
          } else {
            state.error = action.error as ErrorType;
          }
        },
      );
  },
});
export const { reset } = eventDashboardSlice.actions;

export const selectLoading = (state: RootState) => state.eventDashboard.loading;
export const selectDetailData = (state: RootState) => state.eventDashboard.data;
export const selectError = (state: RootState) => state.eventDashboard.error;
export const selectTicketSalesAnalysisLoading = (state: RootState) =>
  state.eventDashboard.ticketSalesAnalysisLoading;
export const selectTicketSalesAnalysis = (state: RootState) =>
  state.eventDashboard.ticketSalesAnalysis;

export default eventDashboardSlice.reducer;
