import React from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { MenuKeys } from './MenuKeys';
import { Images } from '../../theme';
import { IMenu } from '../../utils/menuRender';
import { UserScreens } from '../../navigation/Screens';

const MenuList = (): IMenu[] => {
  const { t } = useTranslation();
  return [
    {
      key: MenuKeys.events,
      title: t('Events'),
      icon: <SVG src={Images.Events} />,
      path: UserScreens.Events.path,
      role: UserScreens.Events.role,
    },
    {
      key: MenuKeys.emails,
      title: t('Emails'),
      icon: <SVG src={Images.Emails} />,
      path: UserScreens.Emails.path,
      role: UserScreens.Emails.role,
    },
  ];
};

export default MenuList;
