import { UserRoutes, AuthRoutes } from './Routes';
// User features
import Events from '../features/Events/Loadable';
import EventDetail from '../features/EventDetail/Loadable';
import Profile from '../features/Profile/Loadable';
import PageNotFound from '../features/404Page/Loadable';
import TicketsSold from '../features/TicketsSold/Loadable';
import Emails from '../features/Emails/Loadable';
import CreateEditEmail from '../features/CreateEditEmail/Loadable';
// Auth features
import Login from '../features/Authentication/Login/Loadable';
import ForgotPassword from '../features/Authentication/ForgotPassword/Loadable';
import EventDashboard from '../features/EventDashboard/Loadable';

import { MenuKeys as vMenuKeys } from '../components/VerticalLayout/MenuKeys';
import { UserRoleKeys } from '../constants/Keys';
import EventPageViews from '../features/EventPageViews/Loadable';
import UniqueBuyers from '../features/UniqueBuyers/Loadable';
import UniqueAttendees from '../features/UniqueAttendees/Loadable';

export const UserScreens = {
  Events: {
    title: 'Events - CrowdControl',
    path: UserRoutes.events,
    component: Events,
    subMenuKeys: [],
    menuKeys: [vMenuKeys.events],
    guard: true,
    role: [
      UserRoleKeys.superAdmin,
      UserRoleKeys.organizerAdmin,
      UserRoleKeys.organizerUser,
    ],
  },
  EventInfo: {
    title: 'Event Info - CrowdControl',
    path: UserRoutes.eventInfo,
    component: EventDetail,
    subMenuKeys: [],
    menuKeys: [vMenuKeys.events],
    guard: true,
    role: [
      UserRoleKeys.superAdmin,
      UserRoleKeys.organizerAdmin,
      UserRoleKeys.organizerUser,
    ],
  },
  Profile: {
    title: 'Profile - CrowdControl',
    path: UserRoutes.profile,
    component: Profile,
    subMenuKeys: [vMenuKeys.settings],
    menuKeys: [vMenuKeys.profile],
    guard: true,
    role: [UserRoleKeys.organizerAdmin, UserRoleKeys.organizerUser],
  },
  EventDashboard: {
    title: 'Event Dashboard - CrowdControl',
    path: UserRoutes.eventDashboard,
    component: EventDashboard,
    subMenuKeys: [],
    menuKeys: [vMenuKeys.events],
    guard: true,
  },
  TicketsSold: {
    title: 'Tickets - CrowdControl',
    path: UserRoutes.ticketSold,
    component: TicketsSold,
    subMenuKeys: [],
    menuKeys: [vMenuKeys.events],
    guard: true,
    role: [
      UserRoleKeys.superAdmin,
      UserRoleKeys.organizerAdmin,
      UserRoleKeys.organizerUser,
    ],
  },
  EventPageViews: {
    title: 'Event Page Views - CrowdControl',
    path: UserRoutes.eventPageViews,
    component: EventPageViews,
    subMenuKeys: [],
    menuKeys: [vMenuKeys.events],
    guard: true,
    role: [
      UserRoleKeys.superAdmin,
      UserRoleKeys.organizerAdmin,
      UserRoleKeys.organizerUser,
    ],
  },
  UniqueBuyers: {
    title: 'Unique Buyers - CrowdControl',
    path: UserRoutes.uniqueBuyers,
    component: UniqueBuyers,
    subMenuKeys: [],
    menuKeys: [vMenuKeys.events],
    guard: true,
    role: [
      UserRoleKeys.superAdmin,
      UserRoleKeys.organizerAdmin,
      UserRoleKeys.organizerUser,
    ],
  },
  UniqueAttendees: {
    title: 'Total Ticket Holders - CrowdControl',
    path: UserRoutes.uniqueAttendees,
    component: UniqueAttendees,
    subMenuKeys: [],
    menuKeys: [vMenuKeys.events],
    guard: true,
    role: [
      UserRoleKeys.superAdmin,
      UserRoleKeys.organizerAdmin,
      UserRoleKeys.organizerUser,
    ],
  },
  Emails: {
    title: 'Emails - CrowdControl',
    path: UserRoutes.emails,
    component: Emails,
    subMenuKeys: [],
    menuKeys: [vMenuKeys.emails],
    guard: true,
    role: [
      UserRoleKeys.superAdmin,
      UserRoleKeys.organizerAdmin,
      UserRoleKeys.organizerUser,
    ],
  },
  CreateEmail: {
    title: 'Create Email - CrowdControl',
    path: UserRoutes.createEmail,
    component: CreateEditEmail,
    subMenuKeys: [],
    menuKeys: [vMenuKeys.emails],
    guard: true,
    role: [
      UserRoleKeys.superAdmin,
      UserRoleKeys.organizerAdmin,
      UserRoleKeys.organizerUser,
    ],
  },
  EditEmail: {
    title: 'Edit Email - CrowdControl',
    path: UserRoutes.editEmail,
    component: CreateEditEmail,
    subMenuKeys: [],
    menuKeys: [vMenuKeys.emails],
    guard: true,
    role: [
      UserRoleKeys.superAdmin,
      UserRoleKeys.organizerAdmin,
      UserRoleKeys.organizerUser,
    ],
  },
  PageNotFoundComponent: {
    title: 'Page Not Found - CrowdControl',
    path: UserRoutes.pageNotFound,
    component: PageNotFound,
    subMenuKeys: [],
    menuKeys: [vMenuKeys.events],
    guard: true,
  },
};

export const AuthScreens = {
  Home: {
    title: 'Home - CrowdControl',
    path: UserRoutes.home,
    component: null,
    guard: false,
    exact: true,
    role: undefined,
  },
  Login: {
    title: 'Login - CrowdControl',
    path: AuthRoutes.login,
    component: Login,
    guard: false,
    role: undefined,
  },
  ForgotPassword: {
    title: 'Forgot Password - CrowdControl',
    path: AuthRoutes.forgotPassword,
    component: ForgotPassword,
    guard: false,
    role: undefined,
  },
};
