import Logo from '../assets/images/logo.svg';
import LogoWhiteColor from '../assets/images/logo.png';
import ZoomShareIcon from '../assets/images/icons/zoom-share.svg';
import StatusInterviewed from '../assets/images/icons/status-interviewed.svg';
import StatusExpired from '../assets/images/icons/status-expired.svg';
import ZoomLinkCopy from '../assets/images/icons/zoom-link-copy.svg';
import SayAnswer from '../assets/images/icons/say-answer.svg';
import Good from '../assets/images/icons/good.svg';
import Unavailable from '../assets/images/icons/unavailable.svg';
import Passed from '../assets/images/icons/passed.svg';
import Counter from '../assets/images/icons/counter-icon.svg';
import Events from '../assets/images/icons/events.svg';
import Tickets from '../assets/images/icons/tickets.svg';
import Edit from '../assets/images/icons/edit.svg';
import EditDisabled from '../assets/images/icons/edit-disabled.svg';
import LoginBackground from '../assets/images/login-bg.svg';
import PasswordHidden from '../assets/images/icons/password-hidden.svg';
import Transactions from '../assets/images/icons/transactions.svg';
import MenuUsersIcon from '../assets/images/icons/menu-user.svg';
import CompanyIcon from '../assets/images/icons/company.svg';
import ProfileTipsBg from '../assets/images/profile-tips-bg.png';
import PreviewEye from '../assets/images/icons/preview-eye.svg';
import DeleteIcon from '../assets/images/icons/delete-icon.svg';
import CloseIcon from '../assets/images/icons/close-icon.svg';
import SuccessIcon from '../assets/images/icons/success-icon.svg';
import EditingIcon from '../assets/images/icons/editing-icon.svg';
import NotStartedIcon from '../assets/images/icons/notStarted-icon.svg';
import NotFinishedIcon from '../assets/images/icons/notFinished-icon.svg';
import ProgressBarLine from '../assets/images/icons/progress-bar-line.svg';
import MenuTrigger from '../assets/images/icons/menu-trigger.svg';
import ImageChangeSizeIcon from '../assets/images/icons/image-change-size.svg';
import ImageDeleteIcon from '../assets/images/icons/image-delete.svg';
import ImageUpwardIcon from '../assets/images/icons/image-upward.svg';
import LocationIcon from '../assets/images/icons/location.svg';
import IntelligentIcon from '../assets/images/icons/intelligent-icon.svg';
import CreateNewTicketIcon from '../assets/images/icons/create-new-ticket.svg';
import TicketTipsIcon from '../assets/images/ticket-tips-icon.png';
import EventTipsIcon from '../assets/images/event-tips-icon.png';
import PromoTipsIcon from '../assets/images/promo-tips-icon.png';
import PublishTupsIcon from '../assets/images/publish-tips-icon.png';
import QuestionCircleIcon from '../assets/images/icons/question-circle-icon.svg';
import DeleteOutlinedIcon from '../assets/images/icons/delete.svg';
import MoreOutlinedIcon from '../assets/images/icons/more-icon.svg';
import ExportIcon from '../assets/images/icons/export.svg';
import ClockIcon from '../assets/images/icons/clock.svg';
import LocationRedIcon from '../assets/images/icons/location-red.svg';
import NoEventBanner from '../assets/images/no-event-banner.png';
import CreateNewPromoIcon from '../assets/images/icons/create-new-promo.svg';
import NoDataIcon from '../assets/images/icons/no-data.svg';
import AddNewEventIcon from '../assets/images/icons/add-new-event.svg';
import Editor from '../assets/images/icons/editor.svg';
import EditorDisable from '../assets/images/icons/editor-disable.svg';
import NoSearchDataIcon from '../assets/images/icons/no-search-data.svg';
import OrganiserIcon from '../assets/images/organiser.png';
import OnSaleIcon from '../assets/images/on-sale.svg';
import SoldIcon from '../assets/images/sold-icon.png';
import BackgroundLogo from '../assets/images/background-logo.png';
import QrCodeButton from '../assets/images/qr-code-button.svg';
import TicketsOnSaleIcon from '../assets/images/tickets-onsale.svg';
import VerticalLineIcon from '../assets/images/vertical-line.svg';
import AllTicketSold from '../assets/images/all-ticket-sold.svg';
import PreviewLocationIcon from '../assets/images/location.png';
import MenuOrdersIcon from '../assets/images/icons/menu-orders.svg';
import ClockWhiteIcon from '../assets/images/icons/clock-white.svg';
import CardArrowIcon from '../assets/images/icons/card-arrow.svg';
import AlertCircleIcon from '../assets/images/icons/alert-circle.svg';
import ChampionIcon from '../assets/images/icons/champion.svg';
import RunnerUpIcon from '../assets/images/icons/runner-up.svg';
import ThirdPlaceIcon from '../assets/images/icons/third-place.svg';
import PageNotFoundIcon from '../assets/images/icons/404-page-icon.svg';
import LoadingBall from '../assets/images/loading-ball.gif';
import EmailIcon from '../assets/images/icons/email-icon.svg';
import GenderIcon from '../assets/images/icons/gender-icon.svg';
import BirthIcon from '../assets/images/icons/birth-icon.svg';
import CountryIcon from '../assets/images/icons/country-icon.svg';
import TicketsSoldIcon from '../assets/images/tickets-sold-icon.svg';
import EstimatedGrossSales from '../assets/images/estimated-gross-sales.svg';
import LoginBackgroundImage from '../assets/images/login-background.png';
import LoginLogoIcon from '../assets/images/login-logo-icon.svg';
import EmailIconLine from '../assets/images/email-icon-line.svg';
import Emails from '../assets/images/icons/emails.svg';
import EyeIcon from '../assets/images/icons/eye-icon.svg';
import FacebookIcon from '../assets/images/icons/facebook.svg';
import InstagramIcon from '../assets/images/icons/ins.svg';
import WebsiteIcon from '../assets/images/icons/website.svg';
import ReminderEmail from '../assets/images/reminder-email.png';
import FacebookPng from '../assets/images/icons/facebook.png';
import InstagramPng from '../assets/images/icons/ins.png';
import WebsitePng from '../assets/images/icons/website.png';

const Images = {
  Logo,
  ZoomShareIcon,
  StatusInterviewed,
  StatusExpired,
  ZoomLinkCopy,
  SayAnswer,
  Good,
  Counter,
  Unavailable,
  Passed,
  Events,
  Tickets,
  Edit,
  EditDisabled,
  LoginBackground,
  PasswordHidden,
  Transactions,
  MenuUsersIcon,
  LogoWhiteColor,
  CompanyIcon,
  ProfileTipsBg,
  PreviewEye,
  DeleteIcon,
  CloseIcon,
  ImageChangeSizeIcon,
  ImageDeleteIcon,
  ImageUpwardIcon,
  SuccessIcon,
  EditingIcon,
  NotStartedIcon,
  NotFinishedIcon,
  ProgressBarLine,
  MenuTrigger,
  LocationIcon,
  IntelligentIcon,
  CreateNewTicketIcon,
  TicketTipsIcon,
  EventTipsIcon,
  PromoTipsIcon,
  PublishTupsIcon,
  QuestionCircleIcon,
  DeleteOutlinedIcon,
  MoreOutlinedIcon,
  ExportIcon,
  ClockIcon,
  LocationRedIcon,
  NoEventBanner,
  CreateNewPromoIcon,
  NoDataIcon,
  AddNewEventIcon,
  Editor,
  EditorDisable,
  NoSearchDataIcon,
  OrganiserIcon,
  OnSaleIcon,
  SoldIcon,
  BackgroundLogo,
  QrCodeButton,
  TicketsOnSaleIcon,
  VerticalLineIcon,
  AllTicketSold,
  PreviewLocationIcon,
  MenuOrdersIcon,
  ClockWhiteIcon,
  CardArrowIcon,
  AlertCircleIcon,
  ChampionIcon,
  RunnerUpIcon,
  ThirdPlaceIcon,
  PageNotFoundIcon,
  LoadingBall,
  EmailIcon,
  GenderIcon,
  BirthIcon,
  CountryIcon,
  TicketsSoldIcon,
  EstimatedGrossSales,
  LoginBackgroundImage,
  LoginLogoIcon,
  EmailIconLine,
  Emails,
  EyeIcon,
  FacebookIcon,
  InstagramIcon,
  WebsiteIcon,
  ReminderEmail,
  FacebookPng,
  InstagramPng,
  WebsitePng,
};

export default Images;
