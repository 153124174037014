import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout, Row, Col, Modal } from 'antd';
import { LogoutOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { SiderTheme } from 'antd/lib/layout/Sider';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';

import { reset } from '../../features/TicketsSold/TicketSold.slice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { AuthRoutes } from '../../navigation/Routes';
import { useCookie } from '../../hooks';
import { CookieKeys } from '../../constants/Keys';
import { resetEventRelatedState } from '../../features/Events/Events.slice';
import {
  selectAnyFieldChanged,
  checkAnyFieldChanged,
} from '../../features/CreateEditEmail/CreateEditEmail.slice';
import Images from '../../theme/Images';
import { SidebarWidth, CollapsedWidth } from '../../constants/Layout';
import SidebarContent from './SidebarContent';

const { Sider } = Layout;
const { confirm } = Modal;

const SidebarCmp = styled(Sider)`
  position: fixed;
  z-index: 2;
  min-height: 100vh;
  .ant-menu-submenu-title .anticon + span,
  .ant-menu-submenu-title .ant-menu-item-icon + span {
    margin-left: 13px;
  }
  &.ant-layout-sider
    .ant-menu-dark
    .ant-menu-inline.ant-menu-sub
    .ant-menu-item
    .ant-menu-title-content {
    margin-left: 12px;
  }
`;

const SideBar = ({
  collapsed,
  handleBroken,
  sidebarTheme,
}: {
  collapsed: boolean;
  handleBroken: (c: boolean) => void;
  sidebarTheme: SiderTheme;
}) => {
  const { t } = useTranslation();
  const cookies = useCookie([
    CookieKeys.authUser,
    CookieKeys.authUserName,
    CookieKeys.userNotActiveToken,
  ]);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const anyFieldChanged = useAppSelector(selectAnyFieldChanged);

  const [collapsedWidth, setCollapsedWidth] = useState(CollapsedWidth);

  const resetMethod = () => {
    dispatch(reset());
    dispatch(resetEventRelatedState());
    cookies.removeCookie(CookieKeys.authUser, { path: '/' });
    cookies.removeCookie(CookieKeys.authUserName, { path: '/' });
    cookies.removeCookie(CookieKeys.userNotActiveToken, { path: '/' });
    history.push(AuthRoutes.login);
  };

  const logout = () => {
    if (!anyFieldChanged) {
      resetMethod();
    } else {
      confirm({
        centered: true,
        closable: false,
        okText: t('Stay'),
        cancelText: t('Leave'),
        title: t('Unsaved Content'),
        icon: <ExclamationCircleOutlined />,
        content: t(`Leaving this page will result in losing your content.`),
        onCancel: () => {
          dispatch(checkAnyFieldChanged(false));
          resetMethod();
        },
      });
    }
  };

  return (
    <SidebarCmp
      trigger={null}
      collapsible
      collapsed={collapsed}
      breakpoint="lg"
      collapsedWidth={collapsedWidth}
      theme={sidebarTheme}
      width={SidebarWidth}
      onBreakpoint={(broken: boolean) => {
        if (broken) {
          setCollapsedWidth(0);
        } else {
          setCollapsedWidth(CollapsedWidth);
        }
        handleBroken(broken);
      }}
    >
      <Col lg={24} span={0} className="logo-box">
        <Link to="/" className="logo">
          <span>
            <img src={Images.LogoWhiteColor} alt="" />
          </span>
        </Link>
      </Col>
      <SidebarContent sidebarTheme={sidebarTheme} />
      <Row className="logout-container" onClick={logout}>
        <div className="text">
          <LogoutOutlined className="logout-icon" />
          {t('Logout')}
        </div>
      </Row>
    </SidebarCmp>
  );
};

export default SideBar;
