import { connectRouter } from 'connected-react-router';
import { combineReducers } from '@reduxjs/toolkit';

import layoutReducer from './layout.slice';
import menuReducer from './menu.slice';
import loginReducer from '../features/Authentication/Login/Login.slice';
import eventsReducer from '../features/Events/Events.slice';
import eventsDetailReducer from '../features/EventDetail/EventDetail.slice';
import forgotPasswordReducer from '../features/Authentication/ForgotPassword/ForgotPassword.slice';
import profileReducer from '../features/Profile/Profile.slice';
import eventDashboardReducer from '../features/EventDashboard/EventDashboard.slice';
import ticketSoldReducer from '../features/TicketsSold/TicketSold.slice';
import eventPageViewsReducer from '../features/EventPageViews/EventPageViews.slice';
import uniqueBuyersReducer from '../features/UniqueBuyers/UniqueBuyers.slice';
import uniqueAttendeesReducer from '../features/UniqueAttendees/UniqueAttendees.slice';
import emailListReducer from '../features/Emails/Emails.slice';
import emailEditReducer from '../features/CreateEditEmail/CreateEditEmail.slice';

const createRootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    layout: layoutReducer,
    menu: menuReducer,
    login: loginReducer,
    events: eventsReducer,
    eventDetail: eventsDetailReducer,
    forgotPassword: forgotPasswordReducer,
    profile: profileReducer,
    eventDashboard: eventDashboardReducer,
    ticketSold: ticketSoldReducer,
    eventPageViews: eventPageViewsReducer,
    uniqueBuyers: uniqueBuyersReducer,
    uniqueAttendees: uniqueAttendeesReducer,
    emailList: emailListReducer,
    emailEdit: emailEditReducer,
  });

export default createRootReducer;
