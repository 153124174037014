export const UserRoutes = {
  dashboard: '/dashboard',
  dashboardSaas: '/saas',
  home: '/',
  forms: {
    colors: '/forms-colors',
    elements: '/forms-elements',
    layouts: '/forms-layouts',
    validation: '/forms-validation',
    upload: '/forms-upload',
    wizard: '/forms-wizard',
  },
  tablesBasic: '/tables-basic',
  tablesResponsive: '/tables-responsive',
  tablesControlColumn: '/tables-control-column',
  uielements: {
    uiAlerts: '/ui-alerts',
    uiCards: '/ui-cards',
    uiProgress: '/ui-progress',
    uiTypography: '/ui-typography',
    uiRates: '/ui-rates',
    uiNotification: '/ui-notification',
  },
  events: '/events',
  createEvent: '/create-event',
  editEvent: '/edit-event/:id',
  eventDetail: '/event-detail/:id',
  users: '/users',
  userDetail: '/user/:userId',
  userTicketDetail: '/user-ticket-detail/:ticketId',
  tickets: '/tickets',
  settings: '/settings',
  profile: '/settings/profile',
  userAndPermissions: '/settings/user-permissions',
  ticketDetail: '/ticket-detail/:ticketId',
  eventInfo: '/event-info/:id',
  eventTickets: '/event-tickets/:id',
  eventTicketsDetail: '/event-tickets-detail/:eventId/:ticketId',
  transactions: '/transactions',
  transactionsDetail: '/transactions-detail/:transactionsId',
  orders: '/orders',
  eventDashboard: '/event-dashboard/:id/:name',
  ticketSold: '/events-tickets/:id/:name',
  pageNotFound: '/404',
  eventPageViews: '/event-page-views/:id/:name',
  uniqueBuyers: '/unique-buyers/:id/:name',
  uniqueAttendees: '/total-ticket-holders/:id/:name',
  emails: '/emails',
  createEmail: '/create-email',
  editEmail: '/edit-email/:id',
};

export const AuthRoutes = {
  login: '/login',
  register: '/register',
  forgotPassword: '/forgot-password',
  changePassword: '/change-password',
};
